import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _43b98635 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _ba26bc76 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _788d7470 = () => interopDefault(import('../pages/member/index.vue' /* webpackChunkName: "pages/member/index" */))
const _0fa539ce = () => interopDefault(import('../pages/member/complete/index.vue' /* webpackChunkName: "pages/member/complete/index" */))
const _380149e1 = () => interopDefault(import('../pages/member/confirm/index.vue' /* webpackChunkName: "pages/member/confirm/index" */))
const _6d5738a2 = () => interopDefault(import('../pages/mypage/contact/index.vue' /* webpackChunkName: "pages/mypage/contact/index" */))
const _7e8e2126 = () => interopDefault(import('../pages/mypage/contract/index.vue' /* webpackChunkName: "pages/mypage/contract/index" */))
const _6237bc4e = () => interopDefault(import('../pages/mypage/hospital/index.vue' /* webpackChunkName: "pages/mypage/hospital/index" */))
const _ac3a468c = () => interopDefault(import('../pages/mypage/lovot/index.vue' /* webpackChunkName: "pages/mypage/lovot/index" */))
const _44a8f483 = () => interopDefault(import('../pages/mypage/myaccount/index.vue' /* webpackChunkName: "pages/mypage/myaccount/index" */))
const _11167bb2 = () => interopDefault(import('../pages/mypage/news/index.vue' /* webpackChunkName: "pages/mypage/news/index" */))
const _227d3bca = () => interopDefault(import('../pages/mypage/official-supporters/index.vue' /* webpackChunkName: "pages/mypage/official-supporters/index" */))
const _5df00ddc = () => interopDefault(import('../pages/mypage/contact/complete/index.vue' /* webpackChunkName: "pages/mypage/contact/complete/index" */))
const _a3b687da = () => interopDefault(import('../pages/mypage/contact/confirm/index.vue' /* webpackChunkName: "pages/mypage/contact/confirm/index" */))
const _b580fe32 = () => interopDefault(import('../pages/mypage/contact/history/index.vue' /* webpackChunkName: "pages/mypage/contact/history/index" */))
const _0280ee92 = () => interopDefault(import('../pages/mypage/contract/billing/index.vue' /* webpackChunkName: "pages/mypage/contract/billing/index" */))
const _56eea7f2 = () => interopDefault(import('../pages/mypage/contract/billing_address/index.vue' /* webpackChunkName: "pages/mypage/contract/billing_address/index" */))
const _20e5f5b0 = () => interopDefault(import('../pages/mypage/contract/creditcard/index.vue' /* webpackChunkName: "pages/mypage/contract/creditcard/index" */))
const _75f66870 = () => interopDefault(import('../pages/mypage/hospital/detail/index.vue' /* webpackChunkName: "pages/mypage/hospital/detail/index" */))
const _589faeeb = () => interopDefault(import('../pages/mypage/lovot/owners/index.vue' /* webpackChunkName: "pages/mypage/lovot/owners/index" */))
const _e992f758 = () => interopDefault(import('../pages/mypage/lovot/update/index.vue' /* webpackChunkName: "pages/mypage/lovot/update/index" */))
const _cf1f22dc = () => interopDefault(import('../pages/mypage/myaccount/change/index.vue' /* webpackChunkName: "pages/mypage/myaccount/change/index" */))
const _1c9df218 = () => interopDefault(import('../pages/mypage/myaccount/confirm/index.vue' /* webpackChunkName: "pages/mypage/myaccount/confirm/index" */))
const _4fe74de2 = () => interopDefault(import('../pages/mypage/contact/history/detail/index.vue' /* webpackChunkName: "pages/mypage/contact/history/detail/index" */))
const _72400ae4 = () => interopDefault(import('../pages/mypage/contract/billing_address/change/index.vue' /* webpackChunkName: "pages/mypage/contract/billing_address/change/index" */))
const _dd9a0b10 = () => interopDefault(import('../pages/mypage/contract/billing_address/confirm/index.vue' /* webpackChunkName: "pages/mypage/contract/billing_address/confirm/index" */))
const _f9a175f8 = () => interopDefault(import('../pages/mypage/contract/billing/detail/index.vue' /* webpackChunkName: "pages/mypage/contract/billing/detail/index" */))
const _a8076b66 = () => interopDefault(import('../pages/mypage/contract/creditcard/change/index.vue' /* webpackChunkName: "pages/mypage/contract/creditcard/change/index" */))
const _4fa0a299 = () => interopDefault(import('../pages/mypage/contract/creditcard/confirm/index.vue' /* webpackChunkName: "pages/mypage/contract/creditcard/confirm/index" */))
const _6044180f = () => interopDefault(import('../pages/mypage/contact/history/detail/complete/index.vue' /* webpackChunkName: "pages/mypage/contact/history/detail/complete/index" */))
const _42dd1f80 = () => interopDefault(import('../pages/mypage/contact/history/detail/confirm/index.vue' /* webpackChunkName: "pages/mypage/contact/history/detail/confirm/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _43b98635,
    name: "404"
  }, {
    path: "/error",
    component: _ba26bc76,
    name: "error"
  }, {
    path: "/member",
    component: _788d7470,
    name: "member"
  }, {
    path: "/member/complete",
    component: _0fa539ce,
    name: "member-complete"
  }, {
    path: "/member/confirm",
    component: _380149e1,
    name: "member-confirm"
  }, {
    path: "/mypage/contact",
    component: _6d5738a2,
    name: "mypage-contact"
  }, {
    path: "/mypage/contract",
    component: _7e8e2126,
    name: "mypage-contract"
  }, {
    path: "/mypage/hospital",
    component: _6237bc4e,
    name: "mypage-hospital"
  }, {
    path: "/mypage/lovot",
    component: _ac3a468c,
    name: "mypage-lovot"
  }, {
    path: "/mypage/myaccount",
    component: _44a8f483,
    name: "mypage-myaccount"
  }, {
    path: "/mypage/news",
    component: _11167bb2,
    name: "mypage-news"
  }, {
    path: "/mypage/official-supporters",
    component: _227d3bca,
    name: "mypage-official-supporters"
  }, {
    path: "/mypage/contact/complete",
    component: _5df00ddc,
    name: "mypage-contact-complete"
  }, {
    path: "/mypage/contact/confirm",
    component: _a3b687da,
    name: "mypage-contact-confirm"
  }, {
    path: "/mypage/contact/history",
    component: _b580fe32,
    name: "mypage-contact-history"
  }, {
    path: "/mypage/contract/billing",
    component: _0280ee92,
    name: "mypage-contract-billing"
  }, {
    path: "/mypage/contract/billing_address",
    component: _56eea7f2,
    name: "mypage-contract-billing_address"
  }, {
    path: "/mypage/contract/creditcard",
    component: _20e5f5b0,
    name: "mypage-contract-creditcard"
  }, {
    path: "/mypage/hospital/detail",
    component: _75f66870,
    name: "mypage-hospital-detail"
  }, {
    path: "/mypage/lovot/owners",
    component: _589faeeb,
    name: "mypage-lovot-owners"
  }, {
    path: "/mypage/lovot/update",
    component: _e992f758,
    name: "mypage-lovot-update"
  }, {
    path: "/mypage/myaccount/change",
    component: _cf1f22dc,
    name: "mypage-myaccount-change"
  }, {
    path: "/mypage/myaccount/confirm",
    component: _1c9df218,
    name: "mypage-myaccount-confirm"
  }, {
    path: "/mypage/contact/history/detail",
    component: _4fe74de2,
    name: "mypage-contact-history-detail"
  }, {
    path: "/mypage/contract/billing_address/change",
    component: _72400ae4,
    name: "mypage-contract-billing_address-change"
  }, {
    path: "/mypage/contract/billing_address/confirm",
    component: _dd9a0b10,
    name: "mypage-contract-billing_address-confirm"
  }, {
    path: "/mypage/contract/billing/detail",
    component: _f9a175f8,
    name: "mypage-contract-billing-detail"
  }, {
    path: "/mypage/contract/creditcard/change",
    component: _a8076b66,
    name: "mypage-contract-creditcard-change"
  }, {
    path: "/mypage/contract/creditcard/confirm",
    component: _4fa0a299,
    name: "mypage-contract-creditcard-confirm"
  }, {
    path: "/mypage/contact/history/detail/complete",
    component: _6044180f,
    name: "mypage-contact-history-detail-complete"
  }, {
    path: "/mypage/contact/history/detail/confirm",
    component: _42dd1f80,
    name: "mypage-contact-history-detail-confirm"
  }, {
    path: "*",
    component: _43b98635,
    name: "notfound"
  }, {
    path: "/",
    component: _ac3a468c,
    name: "lovot"
  }, {
    path: "/license",
    beforeEnter: (to, from, next) => {
          // Put the full page url including the protocol http(s) below
          window.location = 'https://lovot.life/terms/license/'
        }
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
